<template>
  <div class="container">
    <h4 class="text-center my-4">Создание курсов</h4>
    <PrimePreloader v-if="loading"/>
    <div v-else>

      <div class="my-3">
        <div v-if="lectureCount||seminarCount" class="border my-2 rounded-3 bg-light text-center p-2">
          <div v-if="teachingLoad.lecture.length">Лекция: {{lectureCount}}</div>
          <div>Семинар: {{seminarCount}}</div>
          <div v-if="teachingLoad.lecture.length">Разница: <b>{{lectureCount-seminarCount}}</b> ({{lectureCount}} -
            {{seminarCount}})
          </div>
        </div>

        <div v-if="teachingLoad.lecture.length" class="mt-3">
          <div class="fw-bold">Лекция</div>
          <div class="row">
            <div v-for="(lecture, lectureIndex) in teachingLoad.lecture" :key="lectureIndex"
                 class="col-md-4 form-check mt-2">
              <input class="form-check-input" type="checkbox" :id="`load${lecture.id}`" :value="lecture.id"
                     v-model="combination.lecture">
              <label class="form-check-label" :for="`load${lecture.id}`">
                <span v-if="lecture.pps_id">
                  {{lecture.lastname}} {{lecture.firstname}}
                </span>
                <span v-else>Вакансия</span>
                ({{lecture.students_count}})
              </label>
            </div>
          </div>

        </div>

        <div v-if="teachingLoad.seminar.length" class="mt-4">
          <div class="fw-bold">Семинар</div>
          <div class="row">
            <div v-for="(seminar, seminarIndex) in teachingLoad.seminar" :key="seminarIndex"
                 class="col-md-4 form-check mt-2">
              <input class="form-check-input" type="checkbox" :id="`load${seminar.id}`" :value="seminar.id"
                     v-model="combination.seminar">
              <label class="form-check-label" :for="`load${seminar.id}`">
                <span v-if="seminar.pps_id">
                  {{seminar.lastname}} {{seminar.firstname}}
                </span>
                <span v-else>Вакансия</span>
                ({{seminar.students_count}})
              </label>
            </div>
          </div>
        </div>

        <div v-if="teachingLoad.seminar.length" class="text-center my-4">
          <Button icon="pi pi-save" label="Создать" :disabled="btnSaveLessonCombination" :loading="combination.loading"
                  @click="saveLessonCombination"/>
        </div>

        <div class="my-5">
          <div v-if="Object.values(teachingLoadByUnionCourse).length">
            <div class="my-2 fs-5">Сохраненные курсы</div>
            <div v-for="(courses, courseKey) in teachingLoadByUnionCourse" :key="courseKey"
                 class="my-2 rounded-3 border px-3 py-2">
              <div class="row">
                <div class="col-md-10">
                  <div v-for="(load, loadIndex) in courses" :key="'load'+loadIndex" class="my-2">
                    <b>{{load.education_type}}</b> {{load.lastname}} {{load.firstname}} ({{load.students_count}})
                  </div>
                </div>
                <div class="col-md-2 text-end">
                  <Button icon="pi pi-trash" class="p-button-danger p-button-rounded mt-2"
                          :loading="loadingDeleteLessonCombination[courseKey]"
                          @click="deleteLessonCombination(courseKey)"/>
                </div>
              </div>

            </div>
          </div>
          <div v-else>Список сохраненных курсов пуст</div>

        </div>


      </div>

    </div>
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import {uniqueArray, groupByArray} from "@/utils/helpers/array.helpers"


  export default {
    name: 'CombineTeachingLoad',
    data() {
      return {
        loading: true,
        query: {
          disciplineId: this.$route.query.discipline_id || 0,
          semester: this.$route.query.semester || 0,
          credit: this.$route.query.credit || 0,
        },
        teachingLoad: {
          lecture: [],
          seminar: [],
        },
        combination: {
          lecture: [],
          seminar: [],
          loading: false
        },
        teachingLoadByUnionCourse: {},
        loadingDeleteLessonCombination: {}
      }
    },
    computed: {
      btnSaveLessonCombination() {
        return !this.combination.seminar.length || (this.teachingLoad.lecture.length ? !this.combination.lecture.length : false)
      },
      lectureCount() {
        return this.combination.lecture.map(i => this.teachingLoad.lecture.find(t => t.id === i)).reduce((sum, i) => +i.students_count + sum, 0)
      },
      seminarCount() {
        return this.combination.seminar.map(i => this.teachingLoad.seminar.find(t => t.id === i)).reduce((sum, i) => +i.students_count + sum, 0)
      }
    },
    methods: {
      ...mapActions('combinedTeachingLoad', ['GET_PPS_TEACHING_LOAD_BY_DISCIPLINE', 'POST_UNION_COURSE', 'DELETE_UNION_COURSE']),
      async saveLessonCombination() {
        this.combination.loading = true
        const res = await this.POST_UNION_COURSE([...this.combination.lecture, ...this.combination.seminar])
        if (res) {
          this.combination.lecture = this.combination.seminar = []
          await this.getLoad()
          this.$message({title: 'Успешно объединено'})
        }
        this.combination.loading = false
      },
      async deleteLessonCombination(id) {
        this.loadingDeleteLessonCombination[id] = true
        const res = await this.DELETE_UNION_COURSE(id)
        if (res) {
          await this.getLoad()
          this.$message({title: 'Успешно удалено'})
        }
        this.loadingDeleteLessonCombination[id] = false
      },
      async getLoad() {
        const teachingLoads = await this.GET_PPS_TEACHING_LOAD_BY_DISCIPLINE({
          education_discipline_id: this.query.disciplineId,
          semester: this.query.semester,
          credit: this.query.credit
        })

        this.teachingLoad.lecture = uniqueArray(teachingLoads.filter(i => +i.education_type_id === 1), 'id')
        this.teachingLoad.seminar = teachingLoads.filter(i => +i.education_type_id === 2).filter(i => !i.union_course_id)

        this.teachingLoadByUnionCourse = groupByArray(teachingLoads.filter(i => !!i.union_course_id), 'union_course_id')
      },
    },
    async mounted() {
      await this.getLoad()
      this.loading = false
    },

  }

</script>