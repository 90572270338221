export function uniqueArray(arr, key) {
  const newObj = arr.reduce((obj, item) => {
    obj[item[key]] = item
    return obj
  }, {})

  return Object.values(newObj)
}


// Функция которая исхода из трех переменных это
// data - массив объектов,
// searchField поле по которому нужно понять уникальный ли объект или нет,
// needField - массив полей из которых при случаи уникальности объекта формируется массив объектов
export function filterUniqueObjects(data, searchField, needFields) {
  const uniqueObjects = {};
  const result = [];

  data.forEach(item => {
    const searchValue = item[searchField];
    if (!uniqueObjects[searchValue]) {
      uniqueObjects[searchValue] = true;
      const newObj = {};
      needFields.forEach(field => {
        newObj[field] = item[field];
      });
      result.push(newObj);
    }
  });

  return result;
}


export function groupByArray(arr, key) {
  return arr.reduce((objGroupBy, item) => {
    (objGroupBy[item[key]] = objGroupBy[item[key]] || []).push(item);
    return objGroupBy;
  }, {})
}


let data =
  [
    {
      name: 'Madi',
      schedule: [
        {
          week: 1,
          lessons: [
            {
              name: 'L',
              status: 1,
            },
            {
              name: 'L',
              status: 1,
            }
          ]
        },
        {

        }
      ]
    }
  ]